* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.amdc-block .flex {
  display: flex;
}

.amdc-block .table-cell {
  display: table-cell;
}

.amdc-block .inline {
  display: inline;
}

.amdc-block .inline-flex {
  display: inline-flex;
}

.amdc-block .block {
  display: block;
}

.amdc-block .flex-row {
  flex-direction: row;
}

.amdc-block .flex-col {
  flex-direction: column;
}

.amdc-block .flex-row-reverse {
  flex-direction: row-reverse;
}

.amdc-block .flex-col-reverse {
  flex-direction: column-reverse;
}

.amdc-block .gap {
  gap: 10px;
}

.amdc-block .gap-3 {gap: 1.5em}
.amdc-block .gap-4 {gap: 2em}

.amdc-block .no-underline {text-decoration: none;}
.amdc-block .hoveruline:hover { text-decoration: underline; }

.amdc-block .flex-wrap {
  flex-wrap: wrap;
}

.amdc-block .flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.amdc-block .flex-nowrap {
  flex-wrap: nowrap;
}

.amdc-block .grow {
  flex-grow: 1;
}

.amdc-block .flex-grow-1 {
  flex: 1 1 auto;
}

.amdc-block .flex-fixed {
  flex: 0 0 auto;
}

.amdc-block .place-content-end {
  place-content: end;
}

.amdc-block .text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.amdc-block .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.amdc-block .text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.amdc-block .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.amdc-block .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.amdc-block .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.amdc-block .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.amdc-block .text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.amdc-block .text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.amdc-block .text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.amdc-block .text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.amdc-block .text-8xl {
  font-size: 6rem;
  line-height: 1;
}
.amdc-block .text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.amdc-block .font-thin {
  font-weight: 100;
}
.amdc-block .font-extralight {
  font-weight: 200;
}
.amdc-block .font-light {
  font-weight: 300;
}
.amdc-block .font-normal {
  font-weight: 400;
}
.amdc-block .font-medium {
  font-weight: 500;
}
.amdc-block .font-semibold {
  font-weight: 600;
}
.amdc-block .font-bold {
  font-weight: 700;
}
.amdc-block .font-extrabold {
  font-weight: 800;
}
.amdc-block .font-black {
  font-weight: 900;
}

.amdc-block .margin-left-30 {
  margin-left: 30px;
}

.amdc-block .italic {
  font-style: italic;
}
.amdc-block .not-italic {
  font-style: normal;
}

.amdc-block .w-0 {
  width: 0px;
}
.amdc-block .w-px {
  width: 1px;
}
.amdc-block .w-1 {
  width: 0.25rem;
}
.amdc-block .w-2 {
  width: 0.5rem;
}
.amdc-block .w-3 {
  width: 0.75rem;
}
.amdc-block .w-4 {
  width: 1rem;
}
.amdc-block .w-5 {
  width: 1.25rem;
}
.amdc-block .w-6 {
  width: 1.5rem;
}
.amdc-block .w-7 {
  width: 1.75rem;
}
.amdc-block .w-8 {
  width: 2rem;
}
.amdc-block .w-9 {
  width: 2.25rem;
}
.amdc-block .w-10 {
  width: 2.5rem;
}
.amdc-block .w-11 {
  width: 2.75rem;
}
.amdc-block .w-12 {
  width: 3rem;
}
.amdc-block .w-14 {
  width: 3.5rem;
}
.amdc-block .w-16 {
  width: 4rem;
}
.amdc-block .w-20 {
  width: 5rem;
}
.amdc-block .w-24 {
  width: 6rem;
}
.amdc-block .w-28 {
  width: 7rem;
}
.amdc-block .w-32 {
  width: 8rem;
}
.amdc-block .w-36 {
  width: 9rem;
}
.amdc-block .w-40 {
  width: 10rem;
}
.amdc-block .w-44 {
  width: 11rem;
}
.amdc-block .w-48 {
  width: 12rem;
}
.amdc-block .w-52 {
  width: 13rem;
}
.amdc-block .w-56 {
  width: 14rem;
}
.amdc-block .w-60 {
  width: 15rem;
}
.amdc-block .w-64 {
  width: 16rem;
}
.amdc-block .w-72 {
  width: 18rem;
}
.amdc-block .w-80 {
  width: 20rem;
}
.amdc-block .w-96 {
  width: 24rem;
}
.amdc-block .w-auto {
  width: auto;
}
.amdc-block .w-1\/2 {
  width: 50%;
}
.amdc-block .w-1\/3 {
  width: 33.333333%;
}
.amdc-block .w-2\/3 {
  width: 66.666667%;
}
.amdc-block .w-1\/4 {
  width: 25%;
}
.amdc-block .w-2\/4 {
  width: 50%;
}
.amdc-block .w-3\/4 {
  width: 75%;
}
.amdc-block .w-1\/5 {
  width: 20%;
}
.amdc-block .w-2\/5 {
  width: 40%;
}
.amdc-block .w-3\/5 {
  width: 60%;
}
.amdc-block .w-4\/5 {
  width: 80%;
}
.amdc-block .w-1\/6 {
  width: 16.666667%;
}
.amdc-block .w-2\/6 {
  width: 33.333333%;
}
.amdc-block .w-3\/6 {
  width: 50%;
}
.amdc-block .w-4\/6 {
  width: 66.666667%;
}
.amdc-block .w-5\/6 {
  width: 83.333333%;
}
.amdc-block .w-1\/12 {
  width: 8.333333%;
}
.amdc-block .w-2\/12 {
  width: 16.666667%;
}
.amdc-block .w-3\/12 {
  width: 25%;
}
.amdc-block .w-4\/12 {
  width: 33.333333%;
}
.amdc-block .w-5\/12 {
  width: 41.666667%;
}
.amdc-block .w-6\/12 {
  width: 50%;
}
.amdc-block .w-7\/12 {
  width: 58.333333%;
}
.amdc-block .w-8\/12 {
  width: 66.666667%;
}
.amdc-block .w-9\/12 {
  width: 75%;
}
.amdc-block .w-10\/12 {
  width: 83.333333%;
}
.amdc-block .w-11\/12 {
  width: 91.666667%;
}
.amdc-block .w-full {
  width: 100%;
}
.amdc-block .w-screen {
  width: 100vw;
}
.amdc-block .w-min {
  width: min-content;
}
.amdc-block .w-max {
  width: max-content;
}

.amdc-block .h-0 {
  height: 0px;
}
.amdc-block .h-px {
  height: 1px;
}
.amdc-block .h-0\.5 {
  height: 0.125rem;
}
.amdc-block .h-1 {
  height: 0.25rem;
}
.amdc-block .h-1\.5 {
  height: 0.375rem;
}
.amdc-block .h-2 {
  height: 0.5rem;
}
.amdc-block .h-2\.5 {
  height: 0.625rem;
}
.amdc-block .h-3 {
  height: 0.75rem;
}
.amdc-block .h-3\.5 {
  height: 0.875rem;
}
.amdc-block .h-4 {
  height: 1rem;
}
.amdc-block .h-5 {
  height: 1.25rem;
}
.amdc-block .h-6 {
  height: 1.5rem;
}
.amdc-block .h-7 {
  height: 1.75rem;
}
.amdc-block .h-8 {
  height: 2rem;
}
.amdc-block .h-9 {
  height: 2.25rem;
}
.amdc-block .h-10 {
  height: 2.5rem;
}
.amdc-block .h-11 {
  height: 2.75rem;
}
.amdc-block .h-12 {
  height: 3rem;
}
.amdc-block .h-14 {
  height: 3.5rem;
}
.amdc-block .h-16 {
  height: 4rem;
}
.amdc-block .h-20 {
  height: 5rem;
}
.amdc-block .h-24 {
  height: 6rem;
}
.amdc-block .h-28 {
  height: 7rem;
}
.amdc-block .h-32 {
  height: 8rem;
}
.amdc-block .h-36 {
  height: 9rem;
}
.amdc-block .h-40 {
  height: 10rem;
}
.amdc-block .h-44 {
  height: 11rem;
}
.amdc-block .h-48 {
  height: 12rem;
}
.amdc-block .h-52 {
  height: 13rem;
}
.amdc-block .h-56 {
  height: 14rem;
}
.amdc-block .h-60 {
  height: 15rem;
}
.amdc-block .h-64 {
  height: 16rem;
}
.amdc-block .h-72 {
  height: 18rem;
}
.amdc-block .h-80 {
  height: 20rem;
}
.amdc-block .h-96 {
  height: 24rem;
}
.amdc-block .h-auto {
  height: auto;
}
.amdc-block .h-1\/2 {
  height: 50%;
}
.amdc-block .h-1\/3 {
  height: 33.333333%;
}
.amdc-block .h-2\/3 {
  height: 66.666667%;
}
.amdc-block .h-1\/4 {
  height: 25%;
}
.amdc-block .h-2\/4 {
  height: 50%;
}
.amdc-block .h-3\/4 {
  height: 75%;
}
.amdc-block .h-1\/5 {
  height: 20%;
}
.amdc-block .h-2\/5 {
  height: 40%;
}
.amdc-block .h-3\/5 {
  height: 60%;
}
.amdc-block .h-4\/5 {
  height: 80%;
}
.amdc-block .h-1\/6 {
  height: 16.666667%;
}
.amdc-block .h-2\/6 {
  height: 33.333333%;
}
.amdc-block .h-3\/6 {
  height: 50%;
}
.amdc-block .h-4\/6 {
  height: 66.666667%;
}
.amdc-block .h-5\/6 {
  height: 83.333333%;
}
.amdc-block .h-full {
  height: 100%;
}
.amdc-block .h-screen {
  height: 100vh;
}

.amdc-block .left {
  text-align: left;
}

.amdc-block .center {
  text-align: center;
}

.amdc-block .right {
  text-align: right;
}

.amdc-block .tile-large {
  min-width: 600px;
  max-width: 600px;
}

.amdc-block .tile-medium {
  min-width: 400px;
  max-width: 400px;
}

.amdc-block .tile-small {
  min-width: 245px;
  max-width: 245px;
}

.amdc-block .border-solid {
  border-style: solid;
}

.amdc-block .border {
  border-width: 1px;
}

.amdc-block .rounded-none {
  border-radius: 0px;
}
.amdc-block .rounded-sm {
  border-radius: 0.125rem;
}
.amdc-block .rounded {
  border-radius: 0.25rem;
}
.amdc-block .rounded-md {
  border-radius: 0.375rem;
}
.amdc-block .rounded-lg {
  border-radius: 0.5rem;
}
.amdc-block .rounded-xl {
  border-radius: 0.75rem;
}
.amdc-block .rounded-2xl {
  border-radius: 1rem;
}
.amdc-block .rounded-3xl {
  border-radius: 1.5rem;
}
.amdc-block .rounded-full {
  border-radius: 9999px;
}
.amdc-block .rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.amdc-block .rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.amdc-block .rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.amdc-block .rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.amdc-block .rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.amdc-block .rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.amdc-block .rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.amdc-block .rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}
.amdc-block .rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}
.amdc-block .rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.amdc-block .rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.amdc-block .rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.amdc-block .rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.amdc-block .rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.amdc-block .rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.amdc-block .rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.amdc-block .rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.amdc-block .rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}
.amdc-block .rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.amdc-block .rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.amdc-block .rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.amdc-block .rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.amdc-block .rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.amdc-block .rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.amdc-block .rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.amdc-block .rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.amdc-block .rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}
.amdc-block .rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.amdc-block .rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.amdc-block .rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.amdc-block .rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.amdc-block .rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.amdc-block .rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.amdc-block .rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.amdc-block .rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.amdc-block .rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.amdc-block .rounded-tl-none {
  border-top-left-radius: 0px;
}
.amdc-block .rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}
.amdc-block .rounded-tl {
  border-top-left-radius: 0.25rem;
}
.amdc-block .rounded-tl-md {
  border-top-left-radius: 0.375rem;
}
.amdc-block .rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}
.amdc-block .rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}
.amdc-block .rounded-tl-2xl {
  border-top-left-radius: 1rem;
}
.amdc-block .rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}
.amdc-block .rounded-tl-full {
  border-top-left-radius: 9999px;
}
.amdc-block .rounded-tr-none {
  border-top-right-radius: 0px;
}
.amdc-block .rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}
.amdc-block .rounded-tr {
  border-top-right-radius: 0.25rem;
}
.amdc-block .rounded-tr-md {
  border-top-right-radius: 0.375rem;
}
.amdc-block .rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.amdc-block .rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}
.amdc-block .rounded-tr-2xl {
  border-top-right-radius: 1rem;
}
.amdc-block .rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}
.amdc-block .rounded-tr-full {
  border-top-right-radius: 9999px;
}
.amdc-block .rounded-br-none {
  border-bottom-right-radius: 0px;
}
.amdc-block .rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}
.amdc-block .rounded-br {
  border-bottom-right-radius: 0.25rem;
}
.amdc-block .rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}
.amdc-block .rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}
.amdc-block .rounded-br-xl {
  border-bottom-right-radius: 0.75rem;
}
.amdc-block .rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}
.amdc-block .rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}
.amdc-block .rounded-br-full {
  border-bottom-right-radius: 9999px;
}
.amdc-block .rounded-bl-none {
  border-bottom-left-radius: 0px;
}
.amdc-block .rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}
.amdc-block .rounded-bl {
  border-bottom-left-radius: 0.25rem;
}
.amdc-block .rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}
.amdc-block .rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}
.amdc-block .rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}
.amdc-block .rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}
.amdc-block .rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}
.amdc-block .rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.amdc-block .font-small {
  font-size: 12px;
}

.amdc-block .flex-1 {
  flex: 1;
}

.amdc-block .flex-auto {
  flex: 1 1 auto;
}

.amdc-block .flex-initial {
  flex: 0 1 auto;
}

.amdc-block .flex-none {
  flex: none;
}

.amdc-block .uppercase {
  text-transform: uppercase;
}

.amdc-block .items-stretch {
  align-items: stretch;
}

.amdc-block .items-end {
  align-items: flex-end;
}

.amdc-block .items-center {
  align-items: center;
}

.amdc-block .border-b {
  border-bottom-width: 1px;
}

.amdc-block .border-t {
  border-top-width: 1px;
}

.amdc-block .border-l-0 {
  border-left-width: 0px;
}

.amdc-block .border-r-0 {
  border-right-width: 0px;
}

.amdc-block .border-t-0 {
  border-top-width: 0px;
}

.amdc-block .border-b-0 {
  border-bottom-width: 0px;
}

.amdc-block .h-4 {
  height: 1rem;
}

.amdc-block .m-0 {
  margin: 0px;
}
.amdc-block .m-px {
  margin: 1px;
}
.amdc-block .m-0\.5 {
  margin: 0.125rem;
}
.amdc-block .m-1 {
  margin: 0.25rem;
}
.amdc-block .m-1\.5 {
  margin: 0.375rem;
}
.amdc-block .m-2 {
  margin: 0.5rem;
}
.amdc-block .m-2\.5 {
  margin: 0.625rem;
}
.amdc-block .m-3 {
  margin: 0.75rem;
}
.amdc-block .m-3\.5 {
  margin: 0.875rem;
}
.amdc-block .m-4 {
  margin: 1rem;
}
.amdc-block .m-5 {
  margin: 1.25rem;
}
.amdc-block .m-6 {
  margin: 1.5rem;
}
.amdc-block .m-7 {
  margin: 1.75rem;
}
.amdc-block .m-8 {
  margin: 2rem;
}
.amdc-block .m-9 {
  margin: 2.25rem;
}
.amdc-block .m-10 {
  margin: 2.5rem;
}
.amdc-block .m-11 {
  margin: 2.75rem;
}
.amdc-block .m-12 {
  margin: 3rem;
}
.amdc-block .m-14 {
  margin: 3.5rem;
}
.amdc-block .m-16 {
  margin: 4rem;
}
.amdc-block .m-20 {
  margin: 5rem;
}
.amdc-block .m-24 {
  margin: 6rem;
}
.amdc-block .m-28 {
  margin: 7rem;
}
.amdc-block .m-32 {
  margin: 8rem;
}
.amdc-block .m-36 {
  margin: 9rem;
}
.amdc-block .m-40 {
  margin: 10rem;
}
.amdc-block .m-44 {
  margin: 11rem;
}
.amdc-block .m-48 {
  margin: 12rem;
}
.amdc-block .m-52 {
  margin: 13rem;
}
.amdc-block .m-56 {
  margin: 14rem;
}
.amdc-block .m-60 {
  margin: 15rem;
}
.amdc-block .m-64 {
  margin: 16rem;
}
.amdc-block .m-72 {
  margin: 18rem;
}
.amdc-block .m-80 {
  margin: 20rem;
}
.amdc-block .m-96 {
  margin: 24rem;
}
.amdc-block .m-auto {
  margin: auto;
}

.amdc-block .p-0 {
  padding: 0px;
}
.amdc-block .p-px {
  padding: 1px;
}
.amdc-block .p-0\.5 {
  padding: 0.125rem;
}
.amdc-block .p-1 {
  padding: 0.25rem;
}
.amdc-block .p-1\.5 {
  padding: 0.375rem;
}
.amdc-block .p-2 {
  padding: 0.5rem;
}
.amdc-block .p-2\.5 {
  padding: 0.625rem;
}
.amdc-block .p-3 {
  padding: 0.75rem;
}
.amdc-block .p-3\.5 {
  padding: 0.875rem;
}
.amdc-block .p-4 {
  padding: 1rem;
}
.amdc-block .p-5 {
  padding: 1.25rem;
}
.amdc-block .p-6 {
  padding: 1.5rem;
}
.amdc-block .p-7 {
  padding: 1.75rem;
}
.amdc-block .p-8 {
  padding: 2rem;
}
.amdc-block .p-9 {
  padding: 2.25rem;
}
.amdc-block .p-10 {
  padding: 2.5rem;
}
.amdc-block .p-11 {
  padding: 2.75rem;
}
.amdc-block .p-12 {
  padding: 3rem;
}
.amdc-block .p-14 {
  padding: 3.5rem;
}
.amdc-block .p-16 {
  padding: 4rem;
}
.amdc-block .p-20 {
  padding: 5rem;
}
.amdc-block .p-24 {
  padding: 6rem;
}
.amdc-block .p-28 {
  padding: 7rem;
}
.amdc-block .p-32 {
  padding: 8rem;
}
.amdc-block .p-36 {
  padding: 9rem;
}
.amdc-block .p-40 {
  padding: 10rem;
}
.amdc-block .p-44 {
  padding: 11rem;
}
.amdc-block .p-48 {
  padding: 12rem;
}
.amdc-block .p-52 {
  padding: 13rem;
}
.amdc-block .p-56 {
  padding: 14rem;
}
.amdc-block .p-60 {
  padding: 15rem;
}
.amdc-block .p-64 {
  padding: 16rem;
}
.amdc-block .p-72 {
  padding: 18rem;
}
.amdc-block .p-80 {
  padding: 20rem;
}
.amdc-block .p-96 {
  padding: 24rem;
}

.amdc-block .px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.amdc-block .px-px {
  padding-left: 1px;
  padding-right: 1px;
}
.amdc-block .px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.amdc-block .px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.amdc-block .px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.amdc-block .px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.amdc-block .px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.amdc-block .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.amdc-block .px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.amdc-block .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.amdc-block .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.amdc-block .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.amdc-block .px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.amdc-block .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.amdc-block .px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.amdc-block .px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.amdc-block .px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}
.amdc-block .px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.amdc-block .px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.amdc-block .px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.amdc-block .px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.amdc-block .px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}
.amdc-block .px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}
.amdc-block .px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.amdc-block .px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}
.amdc-block .px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}
.amdc-block .px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}
.amdc-block .px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}
.amdc-block .px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}
.amdc-block .px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}
.amdc-block .px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}
.amdc-block .px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}
.amdc-block .px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}
.amdc-block .px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}
.amdc-block .px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}
.amdc-block .py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.amdc-block .py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.amdc-block .py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.amdc-block .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.amdc-block .py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.amdc-block .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.amdc-block .py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.amdc-block .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.amdc-block .py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.amdc-block .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.amdc-block .py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.amdc-block .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.amdc-block .py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.amdc-block .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.amdc-block .py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}
.amdc-block .py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.amdc-block .py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}
.amdc-block .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.amdc-block .py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.amdc-block .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.amdc-block .py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.amdc-block .py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.amdc-block .py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.amdc-block .py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.amdc-block .py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.amdc-block .py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.amdc-block .py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.amdc-block .py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.amdc-block .py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}
.amdc-block .py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}
.amdc-block .py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}
.amdc-block .py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}
.amdc-block .py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}
.amdc-block .py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}
.amdc-block .py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}
.amdc-block .pt-0 {
  padding-top: 0px;
}
.amdc-block .pt-px {
  padding-top: 1px;
}
.amdc-block .pt-0\.5 {
  padding-top: 0.125rem;
}
.amdc-block .pt-1 {
  padding-top: 0.25rem;
}
.amdc-block .pt-1\.5 {
  padding-top: 0.375rem;
}
.amdc-block .pt-2 {
  padding-top: 0.5rem;
}
.amdc-block .pt-2\.5 {
  padding-top: 0.625rem;
}
.amdc-block .pt-3 {
  padding-top: 0.75rem;
}
.amdc-block .pt-3\.5 {
  padding-top: 0.875rem;
}
.amdc-block .pt-4 {
  padding-top: 1rem;
}
.amdc-block .pt-5 {
  padding-top: 1.25rem;
}
.amdc-block .pt-6 {
  padding-top: 1.5rem;
}
.amdc-block .pt-7 {
  padding-top: 1.75rem;
}
.amdc-block .pt-8 {
  padding-top: 2rem;
}
.amdc-block .pt-9 {
  padding-top: 2.25rem;
}
.amdc-block .pt-10 {
  padding-top: 2.5rem;
}
.amdc-block .pt-11 {
  padding-top: 2.75rem;
}
.amdc-block .pt-12 {
  padding-top: 3rem;
}
.amdc-block .pt-14 {
  padding-top: 3.5rem;
}
.amdc-block .pt-16 {
  padding-top: 4rem;
}
.amdc-block .pt-20 {
  padding-top: 5rem;
}
.amdc-block .pt-24 {
  padding-top: 6rem;
}
.amdc-block .pt-28 {
  padding-top: 7rem;
}
.amdc-block .pt-32 {
  padding-top: 8rem;
}
.amdc-block .pt-36 {
  padding-top: 9rem;
}
.amdc-block .pt-40 {
  padding-top: 10rem;
}
.amdc-block .pt-44 {
  padding-top: 11rem;
}
.amdc-block .pt-48 {
  padding-top: 12rem;
}
.amdc-block .pt-52 {
  padding-top: 13rem;
}
.amdc-block .pt-56 {
  padding-top: 14rem;
}
.amdc-block .pt-60 {
  padding-top: 15rem;
}
.amdc-block .pt-64 {
  padding-top: 16rem;
}
.amdc-block .pt-72 {
  padding-top: 18rem;
}
.amdc-block .pt-80 {
  padding-top: 20rem;
}
.amdc-block .pt-96 {
  padding-top: 24rem;
}
.amdc-block .pr-0 {
  padding-right: 0px;
}
.amdc-block .pr-px {
  padding-right: 1px;
}
.amdc-block .pr-0\.5 {
  padding-right: 0.125rem;
}
.amdc-block .pr-1 {
  padding-right: 0.25rem;
}
.amdc-block .pr-1\.5 {
  padding-right: 0.375rem;
}
.amdc-block .pr-2 {
  padding-right: 0.5rem;
}
.amdc-block .pr-2\.5 {
  padding-right: 0.625rem;
}
.amdc-block .pr-3 {
  padding-right: 0.75rem;
}
.amdc-block .pr-3\.5 {
  padding-right: 0.875rem;
}
.amdc-block .pr-4 {
  padding-right: 1rem;
}
.amdc-block .pr-5 {
  padding-right: 1.25rem;
}
.amdc-block .pr-6 {
  padding-right: 1.5rem;
}
.amdc-block .pr-7 {
  padding-right: 1.75rem;
}
.amdc-block .pr-8 {
  padding-right: 2rem;
}
.amdc-block .pr-9 {
  padding-right: 2.25rem;
}
.amdc-block .pr-10 {
  padding-right: 2.5rem;
}
.amdc-block .pr-11 {
  padding-right: 2.75rem;
}
.amdc-block .pr-12 {
  padding-right: 3rem;
}
.amdc-block .pr-14 {
  padding-right: 3.5rem;
}
.amdc-block .pr-16 {
  padding-right: 4rem;
}
.amdc-block .pr-20 {
  padding-right: 5rem;
}
.amdc-block .pr-24 {
  padding-right: 6rem;
}
.amdc-block .pr-28 {
  padding-right: 7rem;
}
.amdc-block .pr-32 {
  padding-right: 8rem;
}
.amdc-block .pr-36 {
  padding-right: 9rem;
}
.amdc-block .pr-40 {
  padding-right: 10rem;
}
.amdc-block .pr-44 {
  padding-right: 11rem;
}
.amdc-block .pr-48 {
  padding-right: 12rem;
}
.amdc-block .pr-52 {
  padding-right: 13rem;
}
.amdc-block .pr-56 {
  padding-right: 14rem;
}
.amdc-block .pr-60 {
  padding-right: 15rem;
}
.amdc-block .pr-64 {
  padding-right: 16rem;
}
.amdc-block .pr-72 {
  padding-right: 18rem;
}
.amdc-block .pr-80 {
  padding-right: 20rem;
}
.amdc-block .pr-96 {
  padding-right: 24rem;
}
.amdc-block .pb-0 {
  padding-bottom: 0px;
}
.amdc-block .pb-px {
  padding-bottom: 1px;
}
.amdc-block .pb-0\.5 {
  padding-bottom: 0.125rem;
}
.amdc-block .pb-1 {
  padding-bottom: 0.25rem;
}
.amdc-block .pb-1\.5 {
  padding-bottom: 0.375rem;
}
.amdc-block .pb-2 {
  padding-bottom: 0.5rem;
}
.amdc-block .pb-2\.5 {
  padding-bottom: 0.625rem;
}
.amdc-block .pb-3 {
  padding-bottom: 0.75rem;
}
.amdc-block .pb-3\.5 {
  padding-bottom: 0.875rem;
}
.amdc-block .pb-4 {
  padding-bottom: 1rem;
}
.amdc-block .pb-5 {
  padding-bottom: 1.25rem;
}
.amdc-block .pb-6 {
  padding-bottom: 1.5rem;
}
.amdc-block .pb-7 {
  padding-bottom: 1.75rem;
}
.amdc-block .pb-8 {
  padding-bottom: 2rem;
}
.amdc-block .pb-9 {
  padding-bottom: 2.25rem;
}
.amdc-block .pb-10 {
  padding-bottom: 2.5rem;
}
.amdc-block .pb-11 {
  padding-bottom: 2.75rem;
}
.amdc-block .pb-12 {
  padding-bottom: 3rem;
}
.amdc-block .pb-14 {
  padding-bottom: 3.5rem;
}
.amdc-block .pb-16 {
  padding-bottom: 4rem;
}
.amdc-block .pb-20 {
  padding-bottom: 5rem;
}
.amdc-block .pb-24 {
  padding-bottom: 6rem;
}
.amdc-block .pb-28 {
  padding-bottom: 7rem;
}
.amdc-block .pb-32 {
  padding-bottom: 8rem;
}
.amdc-block .pb-36 {
  padding-bottom: 9rem;
}
.amdc-block .pb-40 {
  padding-bottom: 10rem;
}
.amdc-block .pb-44 {
  padding-bottom: 11rem;
}
.amdc-block .pb-48 {
  padding-bottom: 12rem;
}
.amdc-block .pb-52 {
  padding-bottom: 13rem;
}
.amdc-block .pb-56 {
  padding-bottom: 14rem;
}
.amdc-block .pb-60 {
  padding-bottom: 15rem;
}
.amdc-block .pb-64 {
  padding-bottom: 16rem;
}
.amdc-block .pb-72 {
  padding-bottom: 18rem;
}
.amdc-block .pb-80 {
  padding-bottom: 20rem;
}
.amdc-block .pb-96 {
  padding-bottom: 24rem;
}
.amdc-block .pl-0 {
  padding-left: 0px;
}
.amdc-block .pl-px {
  padding-left: 1px;
}
.amdc-block .pl-0\.5 {
  padding-left: 0.125rem;
}
.amdc-block .pl-1 {
  padding-left: 0.25rem;
}
.amdc-block .pl-1\.5 {
  padding-left: 0.375rem;
}
.amdc-block .pl-2 {
  padding-left: 0.5rem;
}
.amdc-block .pl-2\.5 {
  padding-left: 0.625rem;
}
.amdc-block .pl-3 {
  padding-left: 0.75rem;
}
.amdc-block .pl-3\.5 {
  padding-left: 0.875rem;
}
.amdc-block .pl-4 {
  padding-left: 1rem;
}
.amdc-block .pl-5 {
  padding-left: 1.25rem;
}
.amdc-block .pl-6 {
  padding-left: 1.5rem;
}
.amdc-block .pl-7 {
  padding-left: 1.75rem;
}
.amdc-block .pl-8 {
  padding-left: 2rem;
}
.amdc-block .pl-9 {
  padding-left: 2.25rem;
}
.amdc-block .pl-10 {
  padding-left: 2.5rem;
}
.amdc-block .pl-11 {
  padding-left: 2.75rem;
}
.amdc-block .pl-12 {
  padding-left: 3rem;
}
.amdc-block .pl-14 {
  padding-left: 3.5rem;
}
.amdc-block .pl-16 {
  padding-left: 4rem;
}
.amdc-block .pl-20 {
  padding-left: 5rem;
}
.amdc-block .pl-24 {
  padding-left: 6rem;
}
.amdc-block .pl-28 {
  padding-left: 7rem;
}
.amdc-block .pl-32 {
  padding-left: 8rem;
}
.amdc-block .pl-36 {
  padding-left: 9rem;
}
.amdc-block .pl-40 {
  padding-left: 10rem;
}
.amdc-block .pl-44 {
  padding-left: 11rem;
}
.amdc-block .pl-48 {
  padding-left: 12rem;
}
.amdc-block .pl-52 {
  padding-left: 13rem;
}
.amdc-block .pl-56 {
  padding-left: 14rem;
}
.amdc-block .pl-60 {
  padding-left: 15rem;
}
.amdc-block .pl-64 {
  padding-left: 16rem;
}
.amdc-block .pl-72 {
  padding-left: 18rem;
}
.amdc-block .pl-80 {
  padding-left: 20rem;
}
.amdc-block .pl-96 {
  padding-left: 24rem;
}

.amdc-block .justify-start {
  justify-content: flex-start;
}
.amdc-block .justify-end {
  justify-content: flex-end;
}
.amdc-block .justify-center {
  justify-content: center;
}
.amdc-block .justify-between {
  justify-content: space-between;
}
.amdc-block .justify-around {
  justify-content: space-around;
}
.amdc-block .justify-evenly {
  justify-content: space-evenly;
}

.amdc-block .overflow-hidden {
  overflow: hidden;
}

.amdc-block .border-transparent {
  border-color: transparent;
}
.amdc-block .border-current {
  border-color: currentColor;
}
.amdc-block .border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.amdc-block .border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.amdc-block .border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.amdc-block .border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.amdc-block .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.amdc-block .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.amdc-block .border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.amdc-block .border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.amdc-block .border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.amdc-block .border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.amdc-block .border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.amdc-block .border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.amdc-block .border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.amdc-block .border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.amdc-block .border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.amdc-block .border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.amdc-block .border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.amdc-block .border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.amdc-block .border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.amdc-block .border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.amdc-block .border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.amdc-block .border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.amdc-block .border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.amdc-block .border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.amdc-block .border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.amdc-block .border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.amdc-block .border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.amdc-block .border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.amdc-block .border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.amdc-block .border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.amdc-block .border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.amdc-block .border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.amdc-block .border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.amdc-block .border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.amdc-block .border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.amdc-block .border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.amdc-block .border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.amdc-block .border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.amdc-block .border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.amdc-block .border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.amdc-block .border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.amdc-block .border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.amdc-block .border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.amdc-block .border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.amdc-block .border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.amdc-block .border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.amdc-block .border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.amdc-block .border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.amdc-block .border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.amdc-block .border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.amdc-block .border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.amdc-block .border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.amdc-block .border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.amdc-block .border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.amdc-block .border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.amdc-block .border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.amdc-block .border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.amdc-block .border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.amdc-block .border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.amdc-block .border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.amdc-block .border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.amdc-block .border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.amdc-block .border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.amdc-block .border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.amdc-block .border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.amdc-block .border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.amdc-block .border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.amdc-block .border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.amdc-block .border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.amdc-block .border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.amdc-block .border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.amdc-block .border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.amdc-block .border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.amdc-block .border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.amdc-block .border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.amdc-block .border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.amdc-block .border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.amdc-block .border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.amdc-block .border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.amdc-block .border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.amdc-block .border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.amdc-block .border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}

.amdc-block .text-transparent {
  color: transparent;
}

.amdc-block .text-current {
  color: currentColor;
}

.amdc-block .text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.amdc-block .text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.amdc-block .text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}

.amdc-block .text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.amdc-block .text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.amdc-block .text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.amdc-block .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.amdc-block .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.amdc-block .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.amdc-block .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.amdc-block .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.amdc-block .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.amdc-block .text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}

.amdc-block .text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}

.amdc-block .text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}

.amdc-block .text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}

.amdc-block .text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.amdc-block .text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.amdc-block .text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.amdc-block .text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.amdc-block .text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.amdc-block .text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.amdc-block .text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}

.amdc-block .text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}

.amdc-block .text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}

.amdc-block .text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}

.amdc-block .text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.amdc-block .text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}

.amdc-block .text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.amdc-block .text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}

.amdc-block .text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}

.amdc-block .text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}

.amdc-block .text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}

.amdc-block .text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}

.amdc-block .text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}

.amdc-block .text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}

.amdc-block .text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}

.amdc-block .text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.amdc-block .text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.amdc-block .text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}

.amdc-block .text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}

.amdc-block .text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}

.amdc-block .text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}

.amdc-block .text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}

.amdc-block .text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}

.amdc-block .text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.amdc-block .text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.amdc-block .text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(33, 83, 112, var(--tw-text-opacity));
}

.amdc-block .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.amdc-block .text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.amdc-block .text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.amdc-block .text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}

.amdc-block .text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}

.amdc-block .text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}

.amdc-block .text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}

.amdc-block .text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}

.amdc-block .text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}

.amdc-block .text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.amdc-block .text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.amdc-block .text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.amdc-block .text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}

.amdc-block .text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.amdc-block .text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}

.amdc-block .text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}

.amdc-block .text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}

.amdc-block .text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}

.amdc-block .text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}

.amdc-block .text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}

.amdc-block .text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}

.amdc-block .text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}

.amdc-block .text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}

.amdc-block .text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}

.amdc-block .text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}

.amdc-block .text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.amdc-block .text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}

.amdc-block .text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}

.amdc-block .text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.amdc-block .text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}

.amdc-block .text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.amdc-block .text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.amdc-block .text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}

.amdc-block .text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}

.amdc-block .bg-transparent {
  background-color: transparent;
}
.amdc-block .bg-current {
  background-color: currentColor;
}
.amdc-block .bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.amdc-block .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.amdc-block .bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.amdc-block .bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.amdc-block .bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.amdc-block .bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.amdc-block .bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.amdc-block .bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.amdc-block .bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.amdc-block .bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.amdc-block .bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.amdc-block .bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.amdc-block .bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.amdc-block .bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.amdc-block .bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.amdc-block .bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.amdc-block .bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.amdc-block .bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.amdc-block .bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.amdc-block .bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.amdc-block .bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.amdc-block .bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.amdc-block .bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.amdc-block .bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.amdc-block .bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.amdc-block .bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.amdc-block .bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.amdc-block .bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}

.amdc-block .custom-palette .border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(45, 204, 211, var(--tw-border-opacity));
}

.amdc-block .circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.amdc-block .self-auto {
  align-self: auto;
}
.amdc-block .self-start {
  align-self: flex-start;
}
.amdc-block .self-end {
  align-self: flex-end;
}
.amdc-block .self-center {
  align-self: center;
}
.amdc-block .self-stretch {
  align-self: stretch;
}
.amdc-block .self-baseline {
  align-self: baseline;
}

.amdc-block .justify-self-auto {
  justify-self: auto;
}
.amdc-block .justify-self-start {
  justify-self: start;
}
.amdc-block .justify-self-end {
  justify-self: end;
}
.amdc-block .justify-self-center {
  justify-self: center;
}
.amdc-block .justify-self-stretch {
  justify-self: stretch;
}

.amdc-block .mt-0 {
  margin-top: 0px;
}
.amdc-block .mt-px {
  margin-top: 1px;
}
.amdc-block .mt-0\.5 {
  margin-top: 0.125rem;
}
.amdc-block .mt-1 {
  margin-top: 0.25rem;
}
.amdc-block .mt-1\.5 {
  margin-top: 0.375rem;
}
.amdc-block .mt-2 {
  margin-top: 0.5rem;
}
.amdc-block .mt-2\.5 {
  margin-top: 0.625rem;
}
.amdc-block .mt-3 {
  margin-top: 0.75rem;
}
.amdc-block .mt-3\.5 {
  margin-top: 0.875rem;
}
.amdc-block .mt-4 {
  margin-top: 1rem;
}
.amdc-block .mt-5 {
  margin-top: 1.25rem;
}
.amdc-block .mt-6 {
  margin-top: 1.5rem;
}
.amdc-block .mt-7 {
  margin-top: 1.75rem;
}
.amdc-block .mt-8 {
  margin-top: 2rem;
}
.amdc-block .mt-9 {
  margin-top: 2.25rem;
}
.amdc-block .mt-10 {
  margin-top: 2.5rem;
}
.amdc-block .mt-11 {
  margin-top: 2.75rem;
}
.amdc-block .mt-12 {
  margin-top: 3rem;
}
.amdc-block .mt-14 {
  margin-top: 3.5rem;
}
.amdc-block .mt-16 {
  margin-top: 4rem;
}
.amdc-block .mt-20 {
  margin-top: 5rem;
}
.amdc-block .mt-24 {
  margin-top: 6rem;
}
.amdc-block .mt-28 {
  margin-top: 7rem;
}
.amdc-block .mt-32 {
  margin-top: 8rem;
}
.amdc-block .mt-36 {
  margin-top: 9rem;
}
.amdc-block .mt-40 {
  margin-top: 10rem;
}
.amdc-block .mt-44 {
  margin-top: 11rem;
}
.amdc-block .mt-48 {
  margin-top: 12rem;
}
.amdc-block .mt-52 {
  margin-top: 13rem;
}
.amdc-block .mt-56 {
  margin-top: 14rem;
}
.amdc-block .mt-60 {
  margin-top: 15rem;
}
.amdc-block .mt-64 {
  margin-top: 16rem;
}
.amdc-block .mt-72 {
  margin-top: 18rem;
}
.amdc-block .mt-80 {
  margin-top: 20rem;
}
.amdc-block .mt-96 {
  margin-top: 24rem;
}
.amdc-block .mt-auto {
  margin-top: auto;
}

.amdc-block .-mt-0\.5 {
  margin-top: -0.125rem;
}
.amdc-block .-mt-1 {
  margin-top: -0.25rem;
}
.amdc-block .-mt-1\.5 {
  margin-top: -0.375rem;
}
.amdc-block .-mt-2 {
  margin-top: -0.5rem;
}
.amdc-block .-mt-2\.5 {
  margin-top: -0.625rem;
}
.amdc-block .-mt-3 {
  margin-top: -0.75rem;
}
.amdc-block .-mt-3\.5 {
  margin-top: -0.875rem;
}
.amdc-block .-mt-4 {
  margin-top: -1rem;
}

.amdc-block .mb-0 {
  margin-bottom: 0px;
}
.amdc-block .mb-px {
  margin-bottom: 1px;
}
.amdc-block .mb-0\.5 {
  margin-bottom: 0.125rem;
}
.amdc-block .mb-1 {
  margin-bottom: 0.25rem;
}
.amdc-block .mb-1\.5 {
  margin-bottom: 0.375rem;
}
.amdc-block .mb-2 {
  margin-bottom: 0.5rem;
}
.amdc-block .mb-2\.5 {
  margin-bottom: 0.625rem;
}
.amdc-block .mb-3 {
  margin-bottom: 0.75rem;
}
.amdc-block .mb-3\.5 {
  margin-bottom: 0.875rem;
}
.amdc-block .mb-4 {
  margin-bottom: 1rem;
}
.amdc-block .mb-5 {
  margin-bottom: 1.25rem;
}
.amdc-block .mb-6 {
  margin-bottom: 1.5rem;
}
.amdc-block .mb-7 {
  margin-bottom: 1.75rem;
}
.amdc-block .mb-8 {
  margin-bottom: 2rem;
}
.amdc-block .mb-9 {
  margin-bottom: 2.25rem;
}
.amdc-block .mb-10 {
  margin-bottom: 2.5rem;
}
.amdc-block .mb-11 {
  margin-bottom: 2.75rem;
}
.amdc-block .mb-12 {
  margin-bottom: 3rem;
}
.amdc-block .mb-14 {
  margin-bottom: 3.5rem;
}
.amdc-block .mb-16 {
  margin-bottom: 4rem;
}
.amdc-block .mb-20 {
  margin-bottom: 5rem;
}
.amdc-block .mb-24 {
  margin-bottom: 6rem;
}
.amdc-block .mb-28 {
  margin-bottom: 7rem;
}
.amdc-block .mb-32 {
  margin-bottom: 8rem;
}
.amdc-block .mb-36 {
  margin-bottom: 9rem;
}
.amdc-block .mb-40 {
  margin-bottom: 10rem;
}
.amdc-block .mb-44 {
  margin-bottom: 11rem;
}
.amdc-block .mb-48 {
  margin-bottom: 12rem;
}
.amdc-block .mb-52 {
  margin-bottom: 13rem;
}
.amdc-block .mb-56 {
  margin-bottom: 14rem;
}
.amdc-block .mb-60 {
  margin-bottom: 15rem;
}
.amdc-block .mb-64 {
  margin-bottom: 16rem;
}
.amdc-block .mb-72 {
  margin-bottom: 18rem;
}
.amdc-block .mb-80 {
  margin-bottom: 20rem;
}
.amdc-block .mb-96 {
  margin-bottom: 24rem;
}
.amdc-block .mb-auto {
  margin-bottom: auto;
}

.amdc-block .ml-0 {
  margin-left: 0px;
}
.amdc-block .ml-px {
  margin-left: 1px;
}
.amdc-block .ml-0\.5 {
  margin-left: 0.125rem;
}
.amdc-block .ml-1 {
  margin-left: 0.25rem;
}
.amdc-block .ml-1\.5 {
  margin-left: 0.375rem;
}
.amdc-block .ml-2 {
  margin-left: 0.5rem;
}
.amdc-block .ml-2\.5 {
  margin-left: 0.625rem;
}
.amdc-block .ml-3 {
  margin-left: 0.75rem;
}
.amdc-block .ml-3\.5 {
  margin-left: 0.875rem;
}
.amdc-block .ml-4 {
  margin-left: 1rem;
}
.amdc-block .ml-5 {
  margin-left: 1.25rem;
}
.amdc-block .ml-6 {
  margin-left: 1.5rem;
}
.amdc-block .ml-7 {
  margin-left: 1.75rem;
}
.amdc-block .ml-8 {
  margin-left: 2rem;
}
.amdc-block .ml-9 {
  margin-left: 2.25rem;
}
.amdc-block .ml-10 {
  margin-left: 2.5rem;
}
.amdc-block .ml-11 {
  margin-left: 2.75rem;
}
.amdc-block .ml-12 {
  margin-left: 3rem;
}
.amdc-block .ml-14 {
  margin-left: 3.5rem;
}
.amdc-block .ml-16 {
  margin-left: 4rem;
}
.amdc-block .ml-20 {
  margin-left: 5rem;
}
.amdc-block .ml-24 {
  margin-left: 6rem;
}
.amdc-block .ml-28 {
  margin-left: 7rem;
}
.amdc-block .ml-32 {
  margin-left: 8rem;
}
.amdc-block .ml-36 {
  margin-left: 9rem;
}
.amdc-block .ml-40 {
  margin-left: 10rem;
}
.amdc-block .ml-44 {
  margin-left: 11rem;
}
.amdc-block .ml-48 {
  margin-left: 12rem;
}
.amdc-block .ml-52 {
  margin-left: 13rem;
}
.amdc-block .ml-56 {
  margin-left: 14rem;
}
.amdc-block .ml-60 {
  margin-left: 15rem;
}
.amdc-block .ml-64 {
  margin-left: 16rem;
}
.amdc-block .ml-72 {
  margin-left: 18rem;
}
.amdc-block .ml-80 {
  margin-left: 20rem;
}
.amdc-block .ml-96 {
  margin-left: 24rem;
}
.amdc-block .ml-auto {
  margin-left: auto;
}

.amdc-block .mr-0 {
  margin-right: 0px;
}
.amdc-block .mr-px {
  margin-right: 1px;
}
.amdc-block .mr-0\.5 {
  margin-right: 0.125rem;
}
.amdc-block .mr-1 {
  margin-right: 0.25rem;
}
.amdc-block .mr-1\.5 {
  margin-right: 0.375rem;
}
.amdc-block .mr-2 {
  margin-right: 0.5rem;
}
.amdc-block .mr-2\.5 {
  margin-right: 0.625rem;
}
.amdc-block .mr-3 {
  margin-right: 0.75rem;
}
.amdc-block .mr-3\.5 {
  margin-right: 0.875rem;
}
.amdc-block .mr-4 {
  margin-right: 1rem;
}
.amdc-block .mr-5 {
  margin-right: 1.25rem;
}
.amdc-block .mr-6 {
  margin-right: 1.5rem;
}
.amdc-block .mr-7 {
  margin-right: 1.75rem;
}
.amdc-block .mr-8 {
  margin-right: 2rem;
}
.amdc-block .mr-9 {
  margin-right: 2.25rem;
}
.amdc-block .mr-10 {
  margin-right: 2.5rem;
}
.amdc-block .mr-11 {
  margin-right: 2.75rem;
}
.amdc-block .mr-12 {
  margin-right: 3rem;
}
.amdc-block .mr-14 {
  margin-right: 3.5rem;
}
.amdc-block .mr-16 {
  margin-right: 4rem;
}
.amdc-block .mr-20 {
  margin-right: 5rem;
}
.amdc-block .mr-24 {
  margin-right: 6rem;
}
.amdc-block .mr-28 {
  margin-right: 7rem;
}
.amdc-block .mr-32 {
  margin-right: 8rem;
}
.amdc-block .mr-36 {
  margin-right: 9rem;
}
.amdc-block .mr-40 {
  margin-right: 10rem;
}
.amdc-block .mr-44 {
  margin-right: 11rem;
}
.amdc-block .mr-48 {
  margin-right: 12rem;
}
.amdc-block .mr-52 {
  margin-right: 13rem;
}
.amdc-block .mr-56 {
  margin-right: 14rem;
}
.amdc-block .mr-60 {
  margin-right: 15rem;
}
.amdc-block .mr-64 {
  margin-right: 16rem;
}
.amdc-block .mr-72 {
  margin-right: 18rem;
}
.amdc-block .mr-80 {
  margin-right: 20rem;
}
.amdc-block .mr-96 {
  margin-right: 24rem;
}
.amdc-block .mr-auto {
  margin-right: auto;
}

.amdc-block .min-height-40 {
  min-height: 40px;
}

.amdc-block .border-solid {
  border-style: solid;
}
.amdc-block .border-dashed {
  border-style: dashed;
}
.amdc-block .border-dotted {
  border-style: dotted;
}
.amdc-block .border-double {
  border-style: double;
}
.amdc-block .border-none {
  border-style: none;
}

.amdc-block .cursor-auto {
  cursor: auto;
}
.amdc-block .cursor-default {
  cursor: default;
}
.amdc-block .cursor-pointer {
  cursor: pointer;
}
.amdc-block .cursor-wait {
  cursor: wait;
}
.amdc-block .cursor-text {
  cursor: text;
}
.amdc-block .cursor-move {
  cursor: move;
}
.amdc-block .cursor-help {
  cursor: help;
}
.amdc-block .cursor-not-allowed {
  cursor: not-allowed;
}

.amdc-block .bg-none {
  background: none;
}

.amdc-block .basis-0 {
  flex-basis: 0px;
}
.amdc-block .basis-1 {
  flex-basis: 0.25rem;
}
.amdc-block .basis-2 {
  flex-basis: 0.5rem;
}
.amdc-block .basis-3 {
  flex-basis: 0.75rem;
}
.amdc-block .basis-4 {
  flex-basis: 1rem;
}
.amdc-block .basis-5 {
  flex-basis: 1.25rem;
}
.amdc-block .basis-6 {
  flex-basis: 1.5rem;
}
.amdc-block .basis-7 {
  flex-basis: 1.75rem;
}
.amdc-block .basis-8 {
  flex-basis: 2rem;
}
.amdc-block .basis-9 {
  flex-basis: 2.25rem;
}
.amdc-block .basis-10 {
  flex-basis: 2.5rem;
}
.amdc-block .basis-11 {
  flex-basis: 2.75rem;
}
.amdc-block .basis-12 {
  flex-basis: 3rem;
}
.amdc-block .basis-14 {
  flex-basis: 3.5rem;
}
.amdc-block .basis-16 {
  flex-basis: 4rem;
}
.amdc-block .basis-20 {
  flex-basis: 5rem;
}
.amdc-block .basis-24 {
  flex-basis: 6rem;
}
.amdc-block .basis-28 {
  flex-basis: 7rem;
}
.amdc-block .basis-32 {
  flex-basis: 8rem;
}
.amdc-block .basis-36 {
  flex-basis: 9rem;
}
.amdc-block .basis-40 {
  flex-basis: 10rem;
}
.amdc-block .basis-44 {
  flex-basis: 11rem;
}
.amdc-block .basis-48 {
  flex-basis: 12rem;
}
.amdc-block .basis-52 {
  flex-basis: 13rem;
}
.amdc-block .basis-56 {
  flex-basis: 14rem;
}
.amdc-block .basis-60 {
  flex-basis: 15rem;
}
.amdc-block .basis-64 {
  flex-basis: 16rem;
}
.amdc-block .basis-72 {
  flex-basis: 18rem;
}
.amdc-block .basis-80 {
  flex-basis: 20rem;
}
.amdc-block .basis-96 {
  flex-basis: 24rem;
}
.amdc-block .basis-auto {
  flex-basis: auto;
}
.amdc-block .basis-px {
  flex-basis: 1px;
}
.amdc-block .basis-0\.5 {
  flex-basis: 0.125rem;
}
.amdc-block .basis-1\.5 {
  flex-basis: 0.375rem;
}
.amdc-block .basis-2\.5 {
  flex-basis: 0.625rem;
}
.amdc-block .basis-3\.5 {
  flex-basis: 0.875rem;
}
.amdc-block .basis-1\/2 {
  flex-basis: 50%;
}
.amdc-block .basis-1\/3 {
  flex-basis: 33.333333%;
}
.amdc-block .basis-2\/3 {
  flex-basis: 66.666667%;
}
.amdc-block .basis-1\/4 {
  flex-basis: 25%;
}
.amdc-block .basis-2\/4 {
  flex-basis: 50%;
}
.amdc-block .basis-3\/4 {
  flex-basis: 75%;
}
.amdc-block .basis-1\/5 {
  flex-basis: 20%;
}
.amdc-block .basis-2\/5 {
  flex-basis: 40%;
}
.amdc-block .basis-3\/5 {
  flex-basis: 60%;
}
.amdc-block .basis-4\/5 {
  flex-basis: 80%;
}
.amdc-block .basis-1\/6 {
  flex-basis: 16.666667%;
}
.amdc-block .basis-2\/6 {
  flex-basis: 33.333333%;
}
.amdc-block .basis-3\/6 {
  flex-basis: 50%;
}
.amdc-block .basis-4\/6 {
  flex-basis: 66.666667%;
}
.amdc-block .basis-5\/6 {
  flex-basis: 83.333333%;
}
.amdc-block .basis-1\/12 {
  flex-basis: 8.333333%;
}
.amdc-block .basis-2\/12 {
  flex-basis: 16.666667%;
}
.amdc-block .basis-3\/12 {
  flex-basis: 25%;
}
.amdc-block .basis-4\/12 {
  flex-basis: 33.333333%;
}
.amdc-block .basis-5\/12 {
  flex-basis: 41.666667%;
}
.amdc-block .basis-6\/12 {
  flex-basis: 50%;
}
.amdc-block .basis-7\/12 {
  flex-basis: 58.333333%;
}
.amdc-block .basis-8\/12 {
  flex-basis: 66.666667%;
}
.amdc-block .basis-9\/12 {
  flex-basis: 75%;
}
.amdc-block .basis-10\/12 {
  flex-basis: 83.333333%;
}
.amdc-block .basis-11\/12 {
  flex-basis: 91.666667%;
}
.amdc-block .basis-full {
  flex-basis: 100%;
}

.amdc-block .overflow-auto {
  overflow: auto;
}
.amdc-block .overflow-clip {
  overflow: clip;
}
.amdc-block .overflow-visible {
  overflow: visible;
}
.amdc-block .overflow-scroll {
  overflow: scroll;
}
.amdc-block .overflow-x-auto {
  overflow-x: auto;
}
.amdc-block .overflow-y-auto {
  overflow-y: auto;
}
.amdc-block .overflow-x-hidden {
  overflow-x: hidden;
}
.amdc-block .overflow-y-hidden {
  overflow-y: hidden;
}
.amdc-block .overflow-x-clip {
  overflow-x: clip;
}
.amdc-block .overflow-y-clip {
  overflow-y: clip;
}
.amdc-block .overflow-x-visible {
  overflow-x: visible;
}
.amdc-block .overflow-y-visible {
  overflow-y: visible;
}
.amdc-block .overflow-x-scroll {
  overflow-x: scroll;
}
.amdc-block div[role='toolbar'] {
  height: 100%;
}

.amdc-block .break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.amdc-block .break-words {
  overflow-wrap: break-word;
}
.amdc-block .break-all {
  word-break: break-all;
}

.amdc-block .tab-height {
  height: calc(100% - 32px);
}

.js-plotly-plot,
.plot-container {
  height: 100%;
}

.ms-Checkbox-label {
  line-height: initial;
}

.download-card-form .ms-Label {
  font-size: 14px;
}

.amdc-advanced-search .ms-Button {
  font-size: 12px;
}

.amdc-block .scatter-plot-tab {
  height: 100%;
  width: 100%;
  position: relative;
}

.ms-DetailsHeader-cellName {
  font-size: 12px;
  font-weight: bold;
  height: 36px;
}

.ms-DetailsHeader-cell {
  height: 36px;
  width: 40px;
  white-space: normal;
  text-overflow: clip;
  line-height: normal;
}

.ms-DetailsHeader {
  height: 36px;
  line-height: 36px;
}

.smalltitle {
  font-size: 10px;
}

.ms-DetailsHeader-cellTitle {
  height: 100%;
}

.ms-DetailsRow-cell {
  overflow: visible;
}

.amdc-block .shadow-sm {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}
.amdc-block .shadow {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.amdc-block .shadow-md {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.amdc-block .shadow-lg {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.amdc-block .shadow-xl {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.amdc-block .shadow-2xl {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
.amdc-block .shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}
.amdc-block .shadow-none {
  box-shadow: 0 0 #0000;
}

.amdc-block .max-h-0 {
  max-height: 0px;
}
.amdc-block .max-h-px {
  max-height: 1px;
}
.amdc-block .max-h-0\.5 {
  max-height: 0.125rem; /* 2px */
}
.amdc-block .max-h-1 {
  max-height: 0.25rem; /* 4px */
}
.amdc-block .max-h-1\.5 {
  max-height: 0.375rem; /* 6px */
}
.amdc-block .max-h-2 {
  max-height: 0.5rem; /* 8px */
}
.amdc-block .max-h-2\.5 {
  max-height: 0.625rem; /* 10px */
}
.amdc-block .max-h-3 {
  max-height: 0.75rem; /* 12px */
}
.amdc-block .max-h-3\.5 {
  max-height: 0.875rem; /* 14px */
}
.amdc-block .max-h-4 {
  max-height: 1rem; /* 16px */
}
.amdc-block .max-h-5 {
  max-height: 1.25rem; /* 20px */
}
.amdc-block .max-h-6 {
  max-height: 1.5rem; /* 24px */
}
.amdc-block .max-h-7 {
  max-height: 1.75rem; /* 28px */
}
.amdc-block .max-h-8 {
  max-height: 2rem; /* 32px */
}
.amdc-block .max-h-9 {
  max-height: 2.25rem; /* 36px */
}
.amdc-block .max-h-10 {
  max-height: 2.5rem; /* 40px */
}
.amdc-block .max-h-11 {
  max-height: 2.75rem; /* 44px */
}
.amdc-block .max-h-12 {
  max-height: 3rem; /* 48px */
}
.amdc-block .max-h-14 {
  max-height: 3.5rem; /* 56px */
}
.amdc-block .max-h-16 {
  max-height: 4rem; /* 64px */
}
.amdc-block .max-h-20 {
  max-height: 5rem; /* 80px */
}
.amdc-block .max-h-24 {
  max-height: 6rem; /* 96px */
}
.amdc-block .max-h-28 {
  max-height: 7rem; /* 112px */
}
.amdc-block .max-h-32 {
  max-height: 8rem; /* 128px */
}
.amdc-block .max-h-36 {
  max-height: 9rem; /* 144px */
}
.amdc-block .max-h-40 {
  max-height: 10rem; /* 160px */
}
.amdc-block .max-h-44 {
  max-height: 11rem; /* 176px */
}
.amdc-block .max-h-48 {
  max-height: 12rem; /* 192px */
}
.amdc-block .max-h-52 {
  max-height: 13rem; /* 208px */
}
.amdc-block .max-h-56 {
  max-height: 14rem; /* 224px */
}
.amdc-block .max-h-60 {
  max-height: 15rem; /* 240px */
}
.amdc-block .max-h-64 {
  max-height: 16rem; /* 256px */
}
.amdc-block .max-h-72 {
  max-height: 18rem; /* 288px */
}
.amdc-block .max-h-80 {
  max-height: 20rem; /* 320px */
}
.amdc-block .max-h-96 {
  max-height: 24rem; /* 384px */
}
.amdc-block .max-h-none {
  max-height: none;
}
.amdc-block .max-h-full {
  max-height: 100%;
}
.amdc-block .max-h-screen {
  max-height: 100vh;
}
.amdc-block .max-h-min {
  max-height: min-content;
}
.amdc-block .max-h-max {
  max-height: max-content;
}
.amdc-block .max-h-fit {
  max-height: fit-content;
}


.amdc-block .max-w-64 {
  max-width: 16rem; /* 256px */
}
.amdc-block .max-w-72 {
  max-width: 18rem; /* 288px */
}
.amdc-block .max-w-80 {
  max-width: 20rem; /* 320px */
}
.amdc-block .max-w-96 {
  max-width: 24rem; /* 384px */
}


.amdc-block .blur-none {
  filter: blur(0);
}
.amdc-block .blur-sm {
  filter: blur(4px);
}
.amdc-block .blur {
  filter: blur(8px);
}
.amdc-block .blur-md {
  filter: blur(12px);
}
.amdc-block .blur-lg {
  filter: blur(16px);
}
.amdc-block .blur-xl {
  filter: blur(24px);
}
.amdc-block .blur-2xl {
  filter: blur(40px);
}

.amdc-block .text-left {  text-align:left; }
.amdc-block .right-0 { right: 0 }
.amdc-block .relative { position: relative }
.amdc-block .absolute { position: absolute }


div[role='menubar'] {
  padding: 0px;
}

#usercentrics-root {
  position: absolute;
}

@media screen and (min-width: 640px) {
	.amdc-block .sm\:w-1\/4 {
		width: 25%;
	}

	.amdc-block .sm\:flex-no-wrap {
		flex-wrap: nowrap;
	}
}

@media screen and (min-width: 768px) {
	.amdc-block .md\:w-1\/4 {
		width: 25%;
	}

	.amdc-block .md\:flex-no-wrap {
			flex-wrap: nowrap;
	}
}

